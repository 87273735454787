import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/* The following plugin is a Club GSAP perk */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import heroBackground from "../assets/hero-pozadi.webm";
import leaf from "../assets/leaf.svg";
import bewit from "../assets/bewit.webp";
import benefitsIcon1 from "../assets/relax-ikona.png";
import benefitsIcon2 from "../assets/individualita-ikona.svg";
import benefitsIcon3 from "../assets/priroda-ikona.svg";
import flower from "../assets/květina.webp";
import Footer from "../components/Footer";

import { IoPersonCircleSharp } from "react-icons/io5";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

const Home = () => {
  const [index, setIndex] = useState(localStorage.getItem("index") || 0);
  localStorage.setItem("index", 0);

  const [homepage, setHomePage] = useState(true);

  useEffect(() => {
    ScrollSmoother.create({
      wrapper: "#wrapper",
      content: "#content",
      smooth: 1,
      speed: 1,
      effects: true,
    });
  }, []);

  const [animation, setAnimation] = useState(false);

  const [scrollValue, setScrollValue] = useState(window.scrollY);
  const [scrolled, setScrolled] = useState(false);
  const scrollRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollValue(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // scrollValue animation stuff
  useEffect(() => {
    if (scrollValue > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [scrollValue]);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, []);

  useEffect(() => {
    const animations = [
      { selector: ".p1", x: 700, y: -100, trigger: ".p1" },
      { selector: ".p2", x: -300, y: -150, trigger: ".p2" },
      { selector: ".p3", x: 500, y: -200, trigger: ".p3" },
      { selector: ".p4", x: -800, y: -150, trigger: ".p4" },
      { selector: ".p5", x: 350, y: -50, trigger: ".p5" },
      { selector: ".p6", x: 900, y: -300, trigger: ".p6" },
      { selector: ".p7", x: -650, y: -120, trigger: ".p7" },
      { selector: ".p8", x: -720, y: -350, trigger: ".p8" },
    ];

    animations.forEach(({ selector, x, y, trigger }) => {
      gsap.to(selector, {
        x,
        y,
        scrollTrigger: {
          trigger: trigger,
          scrub: 1,
        },
      });
    });

    gsap.to(".problems__last__item", {
      scale: 70,

      scrollTrigger: {
        trigger: ".problems__last__item",
        start: "center center",
        scrub: 1,
      },
    });
  }, []);

  const scrollToContact = () => {
    gsap.to(window, {
      scrollTo: "#kontakt",
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  // Load and initialize Ecomail widget script
  useEffect(() => {
    window["ecm-widget"] = "ecmwidget";
    window["ecmwidget"] =
      window["ecmwidget"] ||
      function () {
        (window["ecmwidget"].q = window["ecmwidget"].q || []).push(arguments);
      };

    const script = document.createElement("script");
    script.src = "https://d70shl7vidtft.cloudfront.net/widget.js";
    script.id = "6-3fdfd544852ed7431aa64f3b9481afb9";
    script.dataset.a = "kosmetikamichalcikova";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar
        animation={animation}
        scrolled={scrolled}
        scrollValue={scrollValue}
        homepage={homepage}
      />
      <div className="hero__background-overlay"></div>
      <video
        className="hero__background"
        src={heroBackground}
        style={{
          filter: `blur(${scrollValue / 100 < 7 && scrollValue / 50}px)`,
        }}
        autoPlay
        loop
        muted
      >
        <source src={heroBackground} type="video/webm" />
      </video>

      <div id="wrapper">
        <div id="content">
          <main>
            <div
              className="hero"
              style={
                windowWidth > 864
                  ? {
                      filter: `blur(${
                        scrollValue / 100 < 7 && scrollValue / 80
                      }px)`,
                      top: `${-(scrollValue / 150)}px`,
                    }
                  : {}
              }
            >
              <section>
                <div
                  style={
                    windowWidth > 864
                      ? {
                          top: `${scrollValue ? -(scrollValue / 8) + 35 : 35}%`,
                        }
                      : {}
                  }
                  className="hero__title__wrap"
                >
                  <h1
                    className={`${
                      animation && "hero__title__appear"
                    } hero__title`}
                  >
                    Kosmetika Michalčíková
                  </h1>
                </div>
                <div
                  style={
                    windowWidth > 864
                      ? {
                          top: `${scrollValue ? -(scrollValue / 6) + 35 : 35}%`,
                        }
                      : {}
                  }
                  className="hero__description__wrap"
                >
                  <p
                    className={`${
                      animation && "hero__description__active"
                    } hero__description`}
                  >
                    dovolte si krásu
                  </p>
                </div>
                <div
                  className="hero__buttons"
                  style={
                    windowWidth > 864
                      ? {
                          top: `${
                            scrollValue ? -(scrollValue / 3) + 82.5 : 82.5
                          }%`,
                        }
                      : {}
                  }
                >
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear1"
                    } hero__button__wrap`}
                  >
                    <Link
                      to="/"
                      className="hero__button btn"
                      onClick={scrollToContact}
                    >
                      kontakt
                    </Link>
                  </div>
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear2"
                    } hero__button__wrap`}
                  >
                    <Link to="/rezervace" className="hero__button btn">
                      rezervace
                    </Link>
                  </div>
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear3"
                    } hero__button__wrap`}
                  >
                    <Link to="/sluzby" className="hero__button btn">
                      služby
                    </Link>
                  </div>
                </div>
              </section>
            </div>

            <>
              <div className="section1"></div>
              <div className="intro">
                <p className="intro__description">
                  Kde se krása a pohoda setkávají. Ať už jste žena nebo muž,
                  přijďte si dopřát péči s přírodní kosmetikou a zažít proměnu
                  vaší pleti.
                </p>
              </div>
              <div className="benefits">
                <div className="benefits__content">
                  <div className="benefits__col">
                    <div className="benefits__icon__wrap">
                      <img
                        className="benefits__icon"
                        src={benefitsIcon1}
                        alt=""
                      />
                    </div>
                    <h3>Relax a uvolnění</h3>
                    <p>
                      Relaxace a pocit uvolnění jako nikde jinde! Nejen, že
                      ošetřím tvoji pleť, aby zářila, ale zažiješ i
                      nezapomenutelný pocit klidu a harmonie.
                    </p>
                  </div>
                  <div className="benefits__col">
                    <div className="benefits__icon__wrap">
                      <img
                        className="benefits__icon"
                        src={benefitsIcon2}
                        alt=""
                      />
                    </div>
                    <h3>Individualita</h3>
                    <p>
                      Péče o pleť je velmi komplexní a individuální. Proto ti
                      přizpůsobím ošetření přesně na míru abychom docílili těch
                      nejlepších výsledků!
                    </p>
                  </div>
                  <div className="benefits__col">
                    <div className="benefits__icon__wrap">
                      <img
                        className="benefits__icon"
                        src={benefitsIcon3}
                        alt=""
                      />
                    </div>
                    <h3>Kvalita a přiroda</h3>
                    <p>
                      Specializuji se výhradně na 100% PŘÍRODNÍ produkty a
                      procedury , které dodávají přirozené potřeby pleti.
                    </p>
                  </div>
                </div>
              </div>

              <section className="problems">
                <p className="problems__item p1">povislá pleť</p>
                <p className="problems__item p2">tmavé kruhy a otoky</p>
                <p className="problems__item p3">hyperpigmentace</p>
                <p className="problems__item p4">Nerovnoměrný tón pleti</p>
                <p className="problems__item p5">Akné a jizvy po akné</p>
                <p className="problems__item p6">Jemné vrásky</p>
                <p className="problems__item p7">Nadměrný růst chloupků</p>
                <p className="problems__item p8">Suchá a dehydratovaná pleť</p>
              </section>
              <div className="section3">
                <img src={leaf} className="problems__last__item"></img>
                <p className="randomtextchangelater">.</p>
              </div>
              <div className="whitebackground">
                <section className="reviews">
                  <h2>Co si o mně lidé myslí?</h2>
                  <p>
                    Skvělá zkušenost! Kosmetička byla velmi profesionální a
                    pozorná. Prostředí čisté a příjemné, cítila jsem se tam
                    naprosto uvolněně. Výsledky jsou viditelné, pleť je
                    rozjasněná a zdravá. Určitě se vrátím!
                  </p>
                  <div className="reviews__client">
                    <IoPersonCircleSharp className="reviews__client__icon" />
                    <p>Petra H.</p>
                  </div>
                </section>
                <div className="flower__wrap reviews">
                  <img className="flower" src={flower} alt="Okrasná květina" />
                </div>
                <section className="events reviews">
                  <h2>Co se u nás všechno děje?</h2>
                  <p>
                    BEWIT přednášky v salonu Michalčíková jsou zaměřené na
                    esenciální oleje, přírodní produkty a zdravý životní styl.
                    Naučíte se, jak podpořit zdraví pomocí přírody a získáte
                    praktické rady pro každodenní péči o tělo i duši.
                  </p>
                  <div className="events__buttons">
                    <a
                      className="btn events__button"
                      target="_blank"
                      href="https://www.instagram.com/kosmetika.michalcikova?igsh=YjRnejRpY2FmYXc3"
                    >
                      zjistit více
                    </a>
                    <Link className="btn events__button" to="/rezervace">
                      rezervace
                    </Link>
                  </div>
                </section>
                <section className="newsletter">
                  <h2>Nenechte si ujít</h2>
                  <div id="f-6-3fdfd544852ed7431aa64f3b9481afb9"></div>
                  <p>
                    Můj newsletter vám přináší praktické tipy a znalosti z
                    oblasti zdraví a krásy, bez zbytečných reklam. Informuji vás
                    o akcích a novinkách, které vám přináší skutečnou hodnotu a
                    inspiraci.
                  </p>
                </section>
                <section className="essentials">
                  <div className="essentials__text-wrap">
                    <h2>Co používám?</h2>
                    <p>
                      Ve svých službách používám BEWIT, prémiové esenciální
                      oleje a přírodní produkty, které podporují zdraví,
                      harmonii a vnitřní pohodu. Díky nim mohu nabídnout svým
                      klientům tu nejlepší péči založenou na síle přírody.
                    </p>
                    <a
                      className="btn"
                      target="_blank"
                      href="https://bewit.love/"
                    >
                      zjistit více
                    </a>
                  </div>
                  <img src={bewit} alt="Obrázek bewitu" />
                </section>
                {/* <section className='essentials'>
              <img src={bewit} alt="" />
              <div className="essentials__text-wrap">
                <h2>Nechte se inspirovat</h2>
                <p>Ve svých službách používám BEWIT, prémiové esenciální oleje a přírodní produkty, které podporují zdraví, harmonii a vnitřní pohodu. Díky nim mohu nabídnout svým klientům tu nejlepší péči založenou na síle přírody.</p>
                <a className='btn' target='_blank' href="https://www.instagram.com/kosmetika.michalcikova?igsh=YjRnejRpY2FmYXc3">zjistit více</a>
              </div>
            </section> */}
              </div>
            </>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
