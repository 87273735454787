import React from "react";

const GiftCardWidget = () => {
  return (
    <iframe
      src="https://noona.app/cs//mobilnikosmetika/vouchers?iframe=true&darkModeDisabled=true&showCancelButton=true"
      style={{ border: "0", height: "90vh" }}
      width="100%"
      title="Booking Widget"
    ></iframe>
  );
};

export default GiftCardWidget;
