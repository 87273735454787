import React, { useEffect, useState } from "react";
import "./Reservation.css";

import BookingWidget from "./BookingWidget.js";
import GiftCardWidget from "./GiftCardWidget.js";

import goBackArrow from "../assets/krok-zpet-sipka.svg";
import { Link } from "react-router-dom";

const Reservation = () => {
  const [index, setIndex] = useState(
    () => Number(localStorage.getItem("index")) || 0
  );

  useEffect(() => {
    document.title = "Kosmetika Michalčíková | Rezervace";
  }, []);

  const toggleWidget = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  };

  // Render
  return (
    <>
      {(() => {
        switch (index) {
          case 0:
            return (
              <section className="res__intro">
                <button className="btn gift__btn-back" onClick={toggleWidget}>
                  Chci darovat/koupit poukaz
                </button>
                <Link to="/" className="btn-back">
                  <img src={goBackArrow} alt="Zpět domů" />
                </Link>
                <BookingWidget />
              </section>
            );
          case 1:
            return (
              <section className="res__intro">
                <button className="btn gift__btn-back" onClick={toggleWidget}>
                  Chci se rezervovat
                </button>
                <Link to="/" className="btn-back">
                  <img src={goBackArrow} alt="Zpět domů" />
                </Link>
                <GiftCardWidget />
              </section>
            );

          default:
            return <p>Něco se pokazilo {index}</p>;
        }
      })()}
    </>
  );
};

export default Reservation;
